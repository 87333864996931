import { ProductService } from "../../services/products-service"
import { FETCH_ALL_PRODUCTS } from "../constant"

export const fetchallproducts= (filters=null,pageNumber = 1)=> dispatch => {
    
        dispatch({type:"LOADING_TRUE"})
        ProductService.completedProducts(pageNumber, filters)
        .then(res=>{
            
            dispatch({
                type:FETCH_ALL_PRODUCTS,
                payload:res.data
            })
            dispatch({type:"LOADING_FALSE"})

        })
      
}
export const incrementData=()=>{
    return (dispatch)=>{
        dispatch({type:"INCREMENT"})
    }
}
